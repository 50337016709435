import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import styled from "styled-components";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ContactFormWrapper = styled.div`
  width: 80%;
  margin-bottom: 5rem;

  .button__container{
    @media only screen and (max-width: 500px){
      display:flex;
      align-items: center;
      justify-content: center;
    }
  }

  .label_text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }

  .mb-3{
    margin-bottom: 1.5rem !important;
  }

  .react-tel-input input{
    width: 100%;
  }

  .hire_button--form{
    margin-top: 3rem;
    text-transform: uppercase;
    border-radius: 0.5rem;
    width:100%;
    font-size: 20px;
    line-height: 24px;
    height: 40px;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      width:50%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top:1rem;
      border-radius: 3rem;
    }

    @media only screen and (max-width: 500px){
      margin-top: 1rem;
    }
  }

  @media only screen and (max-width: 500px){
    width: 100%;
    padding: 0 1rem;
  }
`;

const HuForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [phoneNum, setPhoneNum] = useState();
  const [website, setWebsite] = useState("https://");
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const myForm = event.target;
    if (myForm.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }
    setValidated(true);
    const formData = new FormData(myForm);
    
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setFormSubmitted(true);
        setTimeout(() => {
          setFormSubmitted(false);
        }, 5000);
    })
      .catch((error) => alert(error));
  };

  return (
    <ContactFormWrapper>
      {formSubmitted && (<div className="min-h-[947px]">
        <p className="font-montserrat text-dark-blue font-medium leading-[108%] !text-[18px] md:!text-[24px] md:text-center">
                We are excited you chose us as your extended Growth Team.<br/><br/>
                We will be contacting you ASAP on the next steps!  
        </p>
      </div>
        
      )}
      {!formSubmitted && 
      (<Form
        name="contact"
        data-netlify="true"
        method="POST"
        netlify-honeypot="bot-field"
        noValidate 
        onSubmit={handleSubmit} 
        validated={validated}
      >
        <input name="form-name" type="hidden" value="contact"/>
        <div hidden>
          <label>
            Don&apos;t fill this part out: <input name="bot-field"/>
          </label>
        </div>
        <Form.Group className="mb-3" controlId="formFullName">
          <Form.Label bsPrefix="label_text">Full Name</Form.Label>
          <Form.Control 
            name="full-name" 
            placeholder="Ade Tiger" 
            required
            type="name"
          />
          <Form.Control.Feedback type="invalid">
            Please enter your full name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCompanyName">
          <Form.Label bsPrefix="label_text">Company</Form.Label>
          <Form.Control 
            name="company-name" 
            placeholder="Company" 
            required
            type="companyName"
          />
          <Form.Control.Feedback type="invalid">
            Please enter your company&apos;s name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPhoneNumber">
          <Form.Label bsPrefix="label_text">Phone Number</Form.Label>
          <Form.Control 
            name="phone-number" 
            onChange={setPhoneNum}
            required
            type="hidden"
            value={phoneNum}
          />
          <PhoneInput
            country={"us"}
            onChange={setPhoneNum}
            value={phoneNum}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your phone number.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formWebsiteUrl">
          <Form.Label bsPrefix="label_text">Website URL</Form.Label>
          <Form.Control 
            name="website-url" 
            onChange={(e) => setWebsite(e.target.value)} 
            placeholder="https://"
            required
            type="url"
            value={website}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your company&apos;s website url.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label bsPrefix="label_text">Email Address</Form.Label>
          <Form.Control 
            name="email" 
            placeholder="yourname@email.com" 
            required
            type="email"
          />
          <Form.Control.Feedback type="invalid">
            Please enter your email address.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formAdBudget">
          <Form.Label bsPrefix="label_text">Monthly Advertising Budget</Form.Label>
          <Form.Control 
            name="budget" 
            placeholder="Budget Amount" 
            required
            type="budgetNumber"
          />
          <Form.Control.Feedback type="invalid">
            Please enter your monthly advertising budget.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formAnythingElse">
          <Form.Label bsPrefix="label_text">Anything Else?</Form.Label>
          <Form.Control 
            name="optional-text" 
            placeholder="Optional" 
            type="misc"
          />
        </Form.Group>

        <div className="button__container">
          <Button bsPrefix='hire_button hire_button--form' type='submit'>Get Started</Button>
        </div>
        
      </Form>)}
    </ContactFormWrapper>
    
  );
};

export default HuForm;