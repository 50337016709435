import React from "react";
import Layout from "../layouts";
import { graphql } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";

import HuContact from "../components/hireUs/huContact";
import SEO from "../components/seo";

const HireUs = ({ data }) => {
  const { nodes } = data.allContentfulHireUsPage;
  const hero = nodes.filter(node => node.sectionTitle === "01_Hire_Us - Form");
  return (
    <Layout>
      <SEO
        description="FFG is your end-to-end Growth Agency. Contact us to learn how we can help you scale your business through strategy, marketing, development, and design."
        title="Contact Us | Full Funnel Growth"
      />
      <HuContact content={hero}/>
    </Layout>
  );
};

export default HireUs;

export const query = graphql`
query hireUsQuery {
  allContentfulHireUsPage {
    nodes {
      sectionTitle
      bodyText {
        text
      }
      orangeBackgroundRectangle{
        title
        gatsbyImageData(
          quality: 100,
          placeholder: BLURRED
        )
      }
      imageAssets {
        title
        url
        gatsbyImageData(
          quality: 100,
          placeholder: BLURRED
        )
      }
    }
  }
}
`;